/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";
import { isBrowser } from "../utilities/checkEnvironment";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";

const HomePage = ({ data }) => {
  if (!data) return null;
  const { page } = data;
  const home = page.data;
  const [customMargin, setCustomMargin] = useState(50);

  // Set image position focus on crop. User select desired crop from prismic.
  const imagePositions = {
    mid: 50,
    left: 25,
    right: 75,
    top: 25,
    bottom: 75,
  };

  useEffect(() => {
    if (isBrowser) {
      const pageTitleElem = document.querySelector(".page-title");
      if (pageTitleElem) {
        setCustomMargin(pageTitleElem.getClientRects()[0].x);
        // window.addEventListener("resize", () =>
        //   setCustomMargin(pageTitleElem.getClientRects()[0].x + 80)
        // );
        // return () =>
        //   window.removeEventListener("resize", () =>
        //     setCustomMargin(pageTitleElem.getClientRects()[0].x + 80)
        //   );
      }
    }
    return null;
  }, []);

  return (
    <>
      <Seo page={page} />
      <Layout>
        <Container>
          <h1 className="page-title mt-3">{home.title}</h1>
          <h3 className="home-subtitle mb-4 mb-sm-5">{home.subtitle}</h3>
          <div className="d-flex my-5">
            <Anchor href={home.completed_projects_link.url}>
              <h4 className="completed-recent me-4 me-sm-5">
                <span className="link-underline">
                  {home.completed_projects_label}
                </span>
              </h4>
            </Anchor>
            <Anchor href={home.recent_projects_link.url}>
              <h4 className="completed-recent">
                <span className="link-underline">
                  {home.recent_projects_label}
                </span>
              </h4>
            </Anchor>
          </div>
        </Container>
        <div style={{ paddingLeft: customMargin }}>
          <Swiper
            speed={1700}
            spaceBetween={30}
            loop
            effect="fade"
            fadeEffect={{ crossFade: true }}
            modules={[EffectFade, Autoplay]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            className="project-swiper my-5"
          >
            {home.slider.map((project) => {
              const horizontalFocus = imagePositions[project.link_to_project.document.data.home_slider_horizontal_focus]
              const verticalFocus = imagePositions[project.link_to_project.document.data.home_slider_vertical_focus]
              return (
                project.link_to_project.document && (
                  <SwiperSlide key={project.link_to_project.document.id}>
                    <Anchor href={project.link_to_project.document.url}>
                      <div className="slide-wraper d-flex">
                        <div className="slider-arrow align-self-end me-3">
                          <StaticImage
                            src="../images/rightArrow.svg"
                            alt="Go to project arrow"
                          />
                        </div>
                        {project.link_to_project.document.data
                          .home_slider_image_desktop.gatsbyImageData && (
                            <GatsbyImage
                              image={
                                project.link_to_project.document.data
                                  .home_slider_image_desktop.gatsbyImageData
                              }
                              objectPosition={`${horizontalFocus}% ${verticalFocus}%`}
                              alt={
                                project.link_to_project.document.data
                                  .home_slider_image_desktop.alt ||
                                "Project Image"
                              }
                            />
                          )}
                        <h4
                          className={`slider-project-title ${project.link_to_project.document.data
                            .home_slider_image_desktop.gatsbyImageData &&
                            "text-white"
                            }`}
                        >
                          {project.link_to_project.document.data.title}
                        </h4>
                      </div>
                    </Anchor>
                  </SwiperSlide>
                )
              );
            })}
          </Swiper>
        </div>
        <h2
          className="home-slogan d-block ps-lg-4"
          style={{ marginLeft: customMargin + 60 }}
        >
          {home.slogan}
        </h2>
        <Container className="my-5 pt-5">
          <Row className="mb-4">
            <Col>
              <h3 className="featured-title">Featured Projects</h3>
            </Col>
            <Col className="d-none d-lg-flex justify-content-end align-items-center">
              <Anchor href={home.completed_projects_link.url}>
                <p className="completed-recent me-4 me-sm-5 mb-0">
                  <span className="link-underline">
                    {home.completed_projects_label}
                  </span>
                </p>
              </Anchor>
              <Anchor href={home.recent_projects_link.url}>
                <p className="completed-recent mb-0">
                  <span className="link-underline">
                    {home.recent_projects_label}
                  </span>
                </p>
              </Anchor>
            </Col>
          </Row>
          <Row className="project-collection">
            {home.featured_projects.map((project) => {
              return (
                project.link_to_project.document && (
                  <Col
                    className="project-card mb-3 px-xl-4"
                    key={project.link_to_project.document.id}
                    md={6}
                  >
                    <Anchor href={project.link_to_project.document.url}>
                      <div className="project-image-wraper">
                        <GatsbyImage
                          image={
                            project.link_to_project.document.data.primary_image
                              .gatsbyImageData
                          }
                          alt={
                            project.link_to_project.document.data.primary_image
                              .alt || "Project Primary Image"
                          }
                          className="primary-image"
                        />
                        <GatsbyImage
                          image={
                            project.link_to_project.document.data
                              .secondary_image.gatsbyImageData
                          }
                          alt={
                            project.link_to_project.document.data
                              .secondary_image.alt || "Project Secondary Image"
                          }
                          className="secondary-image"
                        />
                      </div>
                      <div>
                        <p className="mb-0 link-effect">
                          <strong>
                            {project.link_to_project.document.data.title}
                          </strong>
                        </p>
                        <p className="link-effect">
                          {project.link_to_project.document.data.location}{" "}
                          {project.link_to_project.document.data.year}
                        </p>
                      </div>
                    </Anchor>
                  </Col>
                )
              );
            })}
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query HomepageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicHomepage(lang: { eq: $lang }) {
      data {
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        title
        subtitle
        completed_projects_link {
          url
        }
        completed_projects_label
        recent_projects_link {
          url
        }
        recent_projects_label
        slogan
        slider {
          link_to_project {
            document {
              ... on PrismicProject {
                id
                url
                data {
                  title
                  home_slider_image_desktop {
                    gatsbyImageData(placeholder: BLURRED)
                    alt
                  }
                  home_slider_horizontal_focus
                  home_slider_vertical_focus
                }
              }
            }
          }
        }
        featured_projects {
          link_to_project {
            document {
              ... on PrismicProject {
                id
                url
                data {
                  title
                  location
                  year
                  primary_image {
                    gatsbyImageData(placeholder: BLURRED)
                    alt
                  }
                  secondary_image {
                    gatsbyImageData(placeholder: BLURRED)
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
