/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 * @returns URL for the provided Prismic document.
 */

const defaultLanguage = require("../../config/site-config").default?.siteLang;

exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "homepage": {
      return doc.lang === defaultLanguage ? "/" : `/${doc.lang.split("-")[0]}/`;
    }
    case "simple_page": {
      return doc.lang === defaultLanguage
        ? `/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}/${doc.uid}/`;
    }
    case "project": {
      return doc.lang === defaultLanguage
        ? `/project/${doc.uid}/`
        : `/project/${doc.lang.split("-")[0]}/${doc.uid}/`;
    }
    case "projects": {
      return doc.lang === defaultLanguage
        ? `/projects/`
        : `/projects/${doc.lang.split("-")[0]}/`;
    }
    case "lectures": {
      return doc.lang === defaultLanguage
        ? `/lectures`
        : `/lectures/${doc.lang.split("-")[0]}/`;
    }
    case "category": {
      return doc.lang === defaultLanguage
        ? `/category/${doc.uid}/`
        : `/category/${doc.lang.split("-")[0]}/${doc.uid}/`;
    }
    case "about": {
      return doc.lang === defaultLanguage
        ? `/about`
        : `/about/${doc.lang.split("-")[0]}/`;
    }
    case "contact": {
      return doc.lang === defaultLanguage
        ? `/contact`
        : `/contact/${doc.lang.split("-")[0]}/`;
    }
    default: {
      return "/";
    }
  }
};
